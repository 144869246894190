@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: 400; /* Regular */
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: 700; /* Bold */
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600; /* SemiBold */
    font-style: normal;
}
