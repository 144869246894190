.landing-page {
  font-family: "Poppins", sans-serif;
  color: #333;
}

.landing-page::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("../../assets/images/home-background.jpg");
  background-size: cover;
  background-position: center;
  opacity: 0.6; /* Adjust as needed */
  z-index: -1;
}
